import React, { useState, useEffect } from 'react';
import './vip.css'; // Import the CSS file for styles
import vip from "../assets/vippic.png";
import { MdGroups } from "react-icons/md";

// Vip component
const Vip = ({ imageUrl, title, description, telegramLink }) => {
  return (
    <div className="perfume-cardan"> {/* Apply the perfume-card class */}
      <div className="image-container"> {/* Image container for the image */}
        <img src={vip} alt="Perfume" />
      </div>
      <div className="text-container"> {/* Text container for title and description */}
{/*<h2>{title}</h2>*/}
        <p>{description}</p>
        <button className="buy-button" onClick={() => window.location.href = telegramLink}> {/* Redirect to the Telegram link */}
          <MdGroups size={20} />
        </button>
      </div>
    </div>
  );
};

// Language toggle component
const LanguageToggleVip = () => {
  const [language, setLanguage] = useState('en'); // Default language is English

  // Translations for title and description
  const translations = {
    title: {
      en: <span className='titro'> ENROLL IN THE VIP EXPERIENCE </span>,
      fr: <span className='titro'> INSCRIVEZ-VOUS À L'EXPÉRIENCE VIP </span>,
      ar: <span className='titro' style={{ fontFamily: "tajawal" }}>VIP  اشترك في تجربة </span>,
    },
    description: {
      en: "Keep updated about new launches, fragrance tips and news, as well as receiving free delivery, exclusive offers and discounts on your favourite brands.",
      fr: "Restez informé des nouveaux lancements, des conseils sur les parfums et des actualités, ainsi que de la livraison gratuite, des offres exclusives et des réductions sur vos marques préférées.",
      ar: <span className="arabic-font">ابقَ على اطلاع حول الإطلاقات الجديدة ونصائح العطور والأخبار، بالإضافة إلى الحصول على توصيل مجاني، وعروض حصرية، وخصومات على علاماتك التجارية المفضلة.</span>
    }
  };

  // Function to automatically toggle language every 5 seconds
  useEffect(() => {
    const languageCycle = setInterval(() => {
      setLanguage(prevLanguage => {
        if (prevLanguage === 'en') return 'fr';
        if (prevLanguage === 'fr') return 'ar';
        return 'en';
      });
    }, 5000); // Change language every 5 seconds

    return () => clearInterval(languageCycle); // Cleanup interval on component unmount
  }, []);

  return (
    <div>
      <Vip
        imageUrl src={vip} // Replace with your image URL
        //title={translations.title[language]}
        description={translations.description[language]}
        telegramLink="https://t.me/Qeyamperfume" // Replace with your Telegram channel link
      />
    </div>
  );
};

export default LanguageToggleVip;
