// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import OrderForm from './components/OrderForm';
import AdminPage from './components/AdminPage';
import LoginPage from './components/Login'; // Import the LoginPage component
import './App.css';
import logo from './assets/logo.png';
import { MdPhone , MdLocationPin } from "react-icons/md";
// import { IoIosCart } from "react-icons/io";

import { TiUser } from "react-icons/ti";

function App() {
  const [orders, setOrders] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State for authentication

  const addOrder = (order) => {
    setOrders([...orders, order]);
  };

  const deleteOrder = (index) => {
    setOrders(orders.filter((_, i) => i !== index));
  };

  const markAsServed = (index) => {
    setOrders(
      orders.map((order, i) =>
        i === index ? { ...order, served: true } : order
      )
    );
  };

  const handleLocationClick = () => {
    alert('Port 6 N 1140 Souk El Had - AGADIR');
  };

  return (
    <Router>
      <div className="App">
      <div className="App">
  <header style={{ 
    display: 'flex', 
    position: "fixed",
    width: "100%",
    justifyContent: 'space-between', 
    alignItems: 'center', 
    padding: '5px 1rem',
    backgroundColor: '#E9CA99', 
    color: '#000',
    height: '45px', 
    zIndex: "20000",
  }}>
    {/* Left section: phone and location buttons */}
    <div style={{ 
      display: 'flex', 
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: 1,
    }}>
      <a href="tel:+212628952639" style={{ 
          marginRight: '0.5rem', 
          color: '#000', 
          textDecoration: 'none',
          display: 'flex', 
          alignItems: 'center',
      }}>
        <MdPhone style={{ fontSize: '1.25rem' }} />
      </a>
      <div 
        onClick={handleLocationClick} 
        style={{ 
          marginRight: '0.4rem', 
          color: '#000', 
          cursor: 'pointer',
          display: 'flex', 
          alignItems: 'center'
        }}>
        <MdLocationPin style={{ fontSize: '1.25rem' }} />
      </div>
    </div>

    {/* Center section: logo */}
    <div style={{ flex: 1, textAlign: 'center' }}>
      <Link to="/">
        <img 
          src={logo} 
          alt="Company Logo" 
          style={{ maxHeight: '40px', height: 'auto', cursor: 'pointer' }} 
        />
      </Link>
    </div>

    {/* Right section: admin button */}
    <div style={{ 
      display: 'flex', 
      justifyContent: 'flex-end', 
      flex: 1,
    }}>
      <nav style={{ fontSize: '1.25rem' }}>
        <Link to="/login" style={{ color: '#000', textDecoration: 'none' }}>
          <TiUser />
        </Link>
      </nav>
    </div>
  </header>

  {/* Responsive styles */}
  <style>
    {`
      @media (max-width: 768px) {
        header {
          padding: 5px 0.5rem;
        }

        nav {
          font-size: 1rem; /* Adjusting font size on smaller screens */
        }

        img {
          max-height: 35px; /* Adjust logo size on smaller screens */
        }

        a {
          margin-right: 0.3rem;
        }
      }
    `}
  </style>
</div>




        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/order" element={<OrderForm addOrder={addOrder} />} />
          <Route path="/login" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/admin" element={isAuthenticated ? <AdminPage orders={orders} deleteOrder={deleteOrder} markAsServed={markAsServed} /> : <LoginPage setIsAuthenticated={setIsAuthenticated} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
