import React, { useState } from 'react';
import './PerfumeTips.css'; // Importation des styles
import { GiDelicatePerfume } from "react-icons/gi";
import { FaStar } from "react-icons/fa";

// Emoji flags for French, English, and Morocco
const flags = {
  fr: <span style={{fontWeight:"600" , fontFamily:"poppins" }}>FR</span>,  // French flag
  en: <span  style={{fontWeight:"600" , fontFamily:"poppins"}}>EN</span>,  // UK flag for English
  ar: <span  style={{fontWeight:"600" , fontFamily:"poppins" }}>AR</span>   // Moroccan flag for Arabic
};

const PerfumeTips = () => {
  const [language, setLanguage] = useState('ar'); // Default to Arabic

  // Toggle language between Arabic, French, and English
  const toggleLanguage = () => {
    if (language === 'ar') {
      setLanguage('fr');
    } else if (language === 'fr') {
      setLanguage('en');
    } else {
      setLanguage('ar');
    }
  };

  return (
    <div className={`tips-section ${language === 'ar' ? 'rtl' : ''}`}>
      <div className="language-toggle">
        <button onClick={toggleLanguage}>
          {flags[language]} {/* Display flag according to the selected language */}
        </button>
      </div>
      <h2 className="fade-in">
        {language === 'fr' ? 'Conseils pour bien choisir et utiliser un parfum' 
         : language === 'en' ? 'Tips for Choosing and Using Perfume' 
         : 'نصائح لاختيار العطور واستخدامها بطريقة عقلانية'}
      </h2>

      {language === 'fr' ? (
        <div className="tips-content fade-in">
          <h3>Comment choisir un parfum <GiDelicatePerfume /> </h3>
          <ul>
            <li><strong><FaStar /> Connaître vos goûts :</strong> Optez pour des parfums qui correspondent à vos préférences personnelles, en fonction de ce que vous aimez.</li>
            <li><strong><FaStar /> Tenir compte des saisons :</strong> Pour l'été, préférez des parfums légers et frais, tandis que des senteurs plus profondes et enveloppantes conviennent mieux pour l'hiver.</li>
            <li><strong><FaStar /> Tester avant d'acheter :</strong> Appliquez une petite quantité de parfum sur votre peau pour voir comment il évolue au cours de la journée.</li>
          </ul>
          <h3>Utilisation rationnelle du parfum <GiDelicatePerfume /></h3>
          <ul>
            <li><strong><FaStar /> Appliquer aux bons endroits :</strong> Vaporisez votre parfum sur les points de pulsation comme le cou, les poignets ou derrière les oreilles.</li>
            <li><strong><FaStar /> Ne pas sur-appliquer :</strong> Quelques pulvérisations suffisent pour une fragrance subtile et élégante.</li>
            <li><strong><FaStar /> Hydrater la peau :</strong> Appliquez le parfum après avoir hydraté votre peau pour prolonger sa tenue.</li>
          </ul>
        </div>
      ) : language === 'en' ? (
        <div className="tips-content fade-in">
          <h3>How to Choose a Perfume <GiDelicatePerfume /></h3>
          <ul>
            <li><strong><FaStar /> Know Your Preferences:</strong> Choose perfumes that match your personal taste based on what you enjoy.</li>
            <li><strong><FaStar /> Consider the Seasons:</strong> In summer, opt for light and fresh perfumes, while deeper and warmer scents are better for winter.</li>
            <li><strong><FaStar /> Test Before Buying:</strong> Apply a small amount of perfume on your skin to see how it evolves over the day.</li>
          </ul>
          <h3>Rational Use of Perfume <GiDelicatePerfume /></h3>
          <ul>
            <li><strong><FaStar /> Apply to Pulse Points:</strong> Spray your perfume on pulse points like the neck, wrists, or behind the ears.</li>
            <li><strong><FaStar /> Don't Over-Apply:</strong> A few sprays are enough for a subtle and elegant scent.</li>
            <li><strong><FaStar /> Moisturize Your Skin:</strong> Apply the perfume after moisturizing your skin to help it last longer.</li>
          </ul>
        </div>
      ) : (
        <div className="tips-content fade-in">
          <h3>كيفية اختيار العطر <GiDelicatePerfume /></h3>
          <ul>
            <li><strong><FaStar /> معرفة أذواقك الشخصية:</strong> اختر العطور التي تعكس أذواقك الشخصية وتفضيلاتك الفريدة.</li>
            <li><strong><FaStar /> مراعاة الفصول:</strong> للعطور الصيفية، اختر روائح منعشة وخفيفة، وللشتاء اختر روائح أعمق وأكثر دفئًا.</li>
            <li><strong><FaStar /> التجربة قبل الشراء:</strong> ضع كمية صغيرة من العطر على بشرتك لترى كيف يتطور مع مرور الوقت.</li>
          </ul>
          <h3>الاستخدام الأمثل للعطر <GiDelicatePerfume /></h3>
          <ul>
            <li><strong><FaStar /> وضع العطر في الأماكن المناسبة:</strong> رش العطر على نقاط النبض مثل الرقبة والمعصمين وخلف الأذنين.</li>
            <li><strong><FaStar /> تجنب الإفراط في الاستخدام:</strong> يكفي استخدام بضع رشات للحفاظ على رائحة أنيقة.</li>
            <li><strong><FaStar /> ترطيب البشرة:</strong> ضع العطر بعد ترطيب بشرتك للحفاظ على رائحته لمدة أطول.</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default PerfumeTips;
