import React, { useState, useEffect } from "react";
import './OrderForm.css';
import { IoIosCart } from "react-icons/io";
import { isValidPhoneNumber } from 'libphonenumber-js';
import { addDoc, collection } from 'firebase/firestore'; // Firestore imports
import { db } from '../firebase'; // Import Firestore database

const OrderForm = () => {
  const [order, setOrder] = useState({
    name: '',
    perfumes: [{ name: '', quantity: '' }],
    phone: '',
    city: '',
    address: '',
    served: false,
  });

  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!order.name.trim()) errors.name = 'Le nom est requis';
    if (!isValidPhoneNumber(order.phone, 'MA')) errors.phone = 'Numéro de téléphone invalide';
    if (!order.city.trim()) errors.city = 'La ville est requise';
    if (!order.address.trim()) errors.address = "L'adresse est requise";
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear error on change
  };

  const handlePerfumeChange = (e, index, field) => {
    const perfumes = [...order.perfumes];
    perfumes[index][field] = e.target.value;
    setOrder({ ...order, perfumes });
    setErrors({ ...errors, perfumes: '' }); // Clear error for perfumes on change
  };

  const addPerfumeChoice = () => {
    setOrder({ ...order, perfumes: [...order.perfumes, { name: '', quantity: '' }] });
  };

  const removePerfumeChoice = (index) => {
    const perfumes = [...order.perfumes];
    perfumes.splice(index, 1);
    setOrder({ ...order, perfumes });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formattedOrder = {
      name: order.name,
      perfumes: order.perfumes.map(p => ({ name: p.name, quantity: p.quantity })),
      phone: order.phone,
      city: order.city,
      address: order.address,
      served: false,
    };

    try {
      // Send data to Firestore
      await addDoc(collection(db, 'orders'), formattedOrder); 
      setMessage('Commande soumise avec succès !');
      setOrder({
        name: '',
        perfumes: [{ name: '', quantity: '' }],
        phone: '',
        city: '',
        address: '',
        served: false,
      });
      setErrors({});
    } catch (error) {
      console.error('Error submitting order:', error);
      setMessage('Impossible de soumettre la commande. Veuillez réessayer.');
    }
  };






  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    const buttonDiv = document.querySelector(".submit-button-container");
    const buttonTop = buttonDiv.offsetTop;
    const buttonBottom = buttonTop + buttonDiv.offsetHeight;
    const scrollPosition = window.scrollY + window.innerHeight;

    // Check if user has scrolled past the button div or is still within it
    if (scrollPosition < buttonTop || window.scrollY > buttonBottom) {
      setIsFixed(true); // Fix the button when outside of its container
    } else {
      setIsFixed(false); // Return to its original position when within the div
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);












  return (
    <div className="order-form-container">
      <form onSubmit={handleSubmit} className="order-form">
        <div className="offer-section">
          <h2 className="offer-title">عرض خاص</h2>
          <p className="offer-details">
            عند شراء 3 عطور من مجموعتنا المميزة ستحصلون على عطر مجاني ، استمتعوا بتجربة عطرية لا تنسى واجعلو كل لحظة مميزة مع عطور qeyam الفاخرة، اختار الآن واغتنموا هذه الفرصة الرائعة
          </p>
          <p className="price1234">299 MAD</p>
          <IoIosCart style={{ fontSize: '30px', color: '#2fb961' }} />
        </div>

        {order.perfumes.map((perfume, index) => (
          <div key={index} className="perfume-choice">
            <div className="form-group">
              <label className={perfume.name && 'active'}>Choix de parfum</label>
              <input
                type="text"
                value={perfume.name}
                onChange={(e) => handlePerfumeChange(e, index, 'name')}
                required
              />
            </div>
          { /* <div className="form-group">
              <label className={perfume.quantity && 'active'}>Quantité (ml)</label>
              <input
                type="number"
                min="0"
                value={perfume.quantity}
                onChange={(e) => handlePerfumeChange(e, index, 'quantity')}
                required
              />
            </div>*/}
            {index > 0 && (
              <button type="button" className="delet-perfume-button" onClick={() => removePerfumeChoice(index)}>
                - Supprimer ce Parfum
              </button>
            )}
            {index === order.perfumes.length - 1 && (
              <button
                type="button"
                className="add-perfume-button"
                onClick={addPerfumeChoice}
              >
                + Ajouter Un Parfum
              </button>
            )}
          </div>
        ))}

        <div className="form-group">
          <label className={order.name && 'active'}>Nom</label>
          <input
            type="text"
            name="name"
            value={order.name}
            onChange={handleChange}
            required
          />
          {errors.name && <small className="error-text">{errors.name}</small>}
        </div>

        <div className="form-group">
          <label className={order.phone && 'active'}>Telephone</label>
          <input
            type="tel"
            name="phone"
            value={order.phone}
            onChange={handleChange}
            required
          />
          {errors.phone && <small className="error-text">{errors.phone}</small>}
        </div>

        <div className="form-group">
          <label className={order.city && 'active'}>Ville</label>
          <input
            type="text"
            name="city"
            value={order.city}
            onChange={handleChange}
            required
          />
          {errors.city && <small className="error-text">{errors.city}</small>}
        </div>

        <div className="form-group">
          <label className={order.address && 'active'}>Adresse de livraison</label>
          <input
            type="text"
            name="address"
            value={order.address}
            onChange={handleChange}
            required
          />
          {errors.address && <small className="error-text">{errors.address}</small>}
        </div>

        <div className="submit-button-container">
      <button
        type="submit"
        className={`submit-button ${isFixed ? "fixed-bottom" : ""}`}
      >
        اطلب المنتج الآن
      </button>
    </div>

        {message && <p className="form-message">{message}</p>}
      </form>
    </div>
  );
};

export default OrderForm;
