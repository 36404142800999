// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import logo from '../assets/logo.png';

function Login({ setIsAuthenticated }) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (password === 'hamza123') { // authentification password (change it)
      setIsAuthenticated(true);
      navigate('/admin');
    } else {
      setError('Mot de passe incorrect');
    }
  };


  return (
    <div className="login-wrapper">
      <div className="login-container">
        <img src={logo} alt="Logo" style={{ maxHeight: '70px', height: 'auto' }} />
        <form onSubmit={handleLogin} className="login-form">
          <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
            placeholder="mot de passe"
            className="login-input"
          />
          
          <button type="submit" className="login-button" onClick={handleLogin}>Connecter</button>
          {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>} {/* pour afficher le message error */}
        </form>
      </div>
    </div>
  );
};

export default Login;
