import React, { useEffect, useState } from 'react';
import './AdminPage.css';
import { FaCheckCircle, FaTrashAlt } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { collection, getDocs, doc, updateDoc, deleteDoc, query, limit, startAfter } from 'firebase/firestore';
import { db } from '../firebase'; // Import Firebase config

const AdminPage = () => {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState(null);
  const [isNextPageAvailable, setIsNextPageAvailable] = useState(true);

  const ORDERS_PER_PAGE = 10;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const ordersQuery = query(collection(db, 'orders'), limit(ORDERS_PER_PAGE));
        const querySnapshot = await getDocs(ordersQuery);

        const ordersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrders(ordersList);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setIsNextPageAvailable(querySnapshot.docs.length === ORDERS_PER_PAGE);
      } catch (error) {
        setError('Error fetching orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const fetchNextPage = async () => {
    setLoadingMore(true);
    try {
      const nextQuery = query(collection(db, 'orders'), startAfter(lastVisible), limit(ORDERS_PER_PAGE));
      const querySnapshot = await getDocs(nextQuery);
      const ordersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOrders(prevOrders => [...prevOrders, ...ordersList]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setIsNextPageAvailable(querySnapshot.docs.length === ORDERS_PER_PAGE);
      setCurrentPage(prevPage => prevPage + 1);
    } catch (error) {
      setError('Error fetching next page.');
    } finally {
      setLoadingMore(false);
    }
  };

  const handleServeOrder = async (id) => {
    try {
      const orderDocRef = doc(db, 'orders', id);
      await updateDoc(orderDocRef, { served: true });
      setOrders(prevOrders => prevOrders.map(order =>
        order.id === id ? { ...order, served: true } : order
      ));
      setSuccessMessage('Order marked as served!');
    } catch (error) {
      alert('Failed to mark order as served.');
    }
  };

  const handleDeleteOrder = async (id) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      try {
        const orderDocRef = doc(db, 'orders', id);
        await deleteDoc(orderDocRef);
        setOrders(prevOrders => prevOrders.filter(order => order.id !== id));
        setSuccessMessage('Order deleted successfully!');
      } catch (error) {
        alert('Failed to delete the order.');
      }
    }
  };

  const filteredOrders = orders.filter(order =>
    ['phone', 'name', 'city'].some(key =>
      order[key]?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const exportToExcel = () => {
    const formattedOrders = filteredOrders.map(order => ({
      Name: order.name,
      Perfumes: order.perfumes.map(p => p.name).join(', '),
      Quantities: order.perfumes.map(p => p.quantity).join(', '),
      Phone: order.phone,
      City: order.city,
      Address: order.address,
      Served: order.served ? 'Yes' : 'No',
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedOrders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
    XLSX.writeFile(workbook, 'orders.xlsx');
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000); // Hides message after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <div className="admin-page-container">
      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : error ? (
        <div className="error">{error}</div>
      ) : (
        <>
          <div className="table-container">
            <table className="orders-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Perfumes</th>
                  <th>Phone</th>
                  <th>City</th>
                  <th>Address</th>
                  <th>Served</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map(order => (
                  <tr key={order.id}>
                    <td>{order.name}</td>
                    <td>{order.perfumes.map(p => p.name).join(', ')}</td>
                    <td>{order.phone}</td>
                    <td>{order.city}</td>
                    <td>{order.address}</td>
                    <td>{order.served ? <FaCheckCircle color="#22bd50" /> : 'No'}</td>
                    <td>
                      {!order.served && (
                        <button
                          onClick={() => handleServeOrder(order.id)}
                          className="serve-button"
                          aria-label="Mark as served"
                        >
                          <FaCheckCircle /> Served
                        </button>
                      )}
                      <button
                        onClick={() => handleDeleteOrder(order.id)}
                        className="delete-button"
                        aria-label="Delete order"
                      >
                        <FaTrashAlt /> Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {isNextPageAvailable && (
            <button onClick={fetchNextPage} className="pagination-button">
              {loadingMore ? 'Loading...' : 'Load More'}
            </button>
          )}
        </>
      )}
      {successMessage && <div className="success-message">{successMessage}</div>}

      {/* Fixed bottom container for search and export */}
      <div className="fixed-bottom-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
          aria-label="Search orders"
        />
        <button className="export-button" onClick={exportToExcel} aria-label="Export orders to Excel">
          Export to Excel
        </button>
      </div>
    </div>
  );
};

export default AdminPage;
