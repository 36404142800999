import React, { useState, useEffect } from 'react';
import OrderForm from './OrderForm';
import './LandingPage.css';
import qualityIcon from '../assets/quality.png';
import warranty from '../assets/warranty.png';
import shipping from '../assets/shipping.png';
import ads1 from '../assets/New Perfumes.png';
import ads2 from '../assets/New Perfumes2.png';
import ads3 from '../assets/New Perfumes3.png';
import ads4 from '../assets/4.png';
import ads5 from '../assets/5.png';
import ads6 from '../assets/6.png';
import ads8 from '../assets/8.png';
import ads9 from '../assets/9.png';
import ads10 from '../assets/20.png';
import ads11 from '../assets/11.png';
import ads12 from '../assets/12.png';
import ads13 from '../assets/13.png';
import { IoIosCart } from "react-icons/io";
import { AiFillTikTok } from "react-icons/ai";
import { PiInstagramLogoFill } from "react-icons/pi";
import { GrCaretPrevious , GrCaretNext } from "react-icons/gr";
import { FaWhatsapp } from "react-icons/fa";
import { RiMenLine , RiWomenLine } from "react-icons/ri";
import { GoStarFill } from "react-icons/go";
import PerfumeTips from './PerfumeTips';

import LanguageToggleVip from './vip';
import ShopByType from "./ShopByType";

// Sample Logos Data (replace these with actual URLs)
const perfumeLogos = [
  { brand: "Christian Dior", logo: "https://seeklogo.com/images/C/christian-dior-logo-F4445FA380-seeklogo.com.png" },
  { brand: "Jean Paul Gaultier", logo: "https://seeklogo.com/images/J/Jean_Paul_Gaultier-logo-5C786D0F52-seeklogo.com.png" },
  { brand: "Giorgio Armani", logo: "https://seeklogo.com/images/E/Emporio_Armani-logo-97EAF719B0-seeklogo.com.png" },
  { brand: "Hermes", logo: "https://seeklogo.com/images/H/hermes-logo-706DC9E640-seeklogo.com.png" },
  { brand: "lattafa", logo: "https://seeklogo.com/images/L/lattafa-logo-BF4C5EB5A4-seeklogo.com.png" },
  { brand: "tom ford", logo: "https://seeklogo.com/images/T/tom-ford-logo-364124339D-seeklogo.com.png" },
  { brand: "Valentino", logo: "https://seeklogo.com/images/V/valentino-logo-D9732C7621-seeklogo.com.png" },
  { brand: "Gucci", logo: "https://seeklogo.com/images/G/Gucci-logo-E3DE085D10-seeklogo.com.png" },
  { brand: "prada", logo: "https://seeklogo.com/images/P/PRADA-logo-47D1CEFA23-seeklogo.com.png" },
  { brand: "yves", logo: "https://seeklogo.com/images/Y/yves-saint-laurent-logo-03C2739F7E-seeklogo.com.png" },
  { brand: "paco", logo: "https://seeklogo.com/images/P/Paco_Rabanne-logo-66AA062BA8-seeklogo.com.png" },
  { brand: "mont", logo: "https://seeklogo.com/images/M/Mont_Blanc-logo-AAF6E2D71B-seeklogo.com.png" },
  { brand: "versace", logo: "https://seeklogo.com/images/V/Versace_Medusa-logo-ADB50F52FE-seeklogo.com.png" },
  { brand: "boss", logo: "https://seeklogo.com/images/H/hugo-boss-logo-17DF621BD9-seeklogo.com.png" },
  { brand: "montal", logo: "https://seeklogo.com/images/M/montale-paris-logo-184BE3CDF7-seeklogo.com.png" },
  { brand: "cartier", logo: "https://seeklogo.com/images/C/Cartier-logo-F9CF641F69-seeklogo.com.png" },
  { brand: "creed", logo: "https://seeklogo.com/images/C/creed-1760-logo-ECE7B8D5D8-seeklogo.com.png" },
  { brand: "alhambra", logo: "	https://dubaiparfums.nl/cdn/shop/collections/MAISON_ALHAMBRA_LOGO_jpg_edited.jpg?v=1688321222" },
  { brand: "x", logo: "		https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5JQRv86La2Bi0TYXT3V98zkAN5ZHYMHVvdw&s" },
  { brand: "lacoste", logo: "https://seeklogo.com/images/L/lacoste-logo-AB7F5E1A27-seeklogo.com.png" },
  // Add more logos as needed
];





// Olfactory Families Component

const perfumes = {
  "Christian Dior": [
    { name: "13 - J'adore", category: "Femme" },
    { name: "14 - Hypnotic Poison", category: "Femme" },
  ],
 

  "Carolina": [
    { name: "1 - Good girl", category: "Femme" },
    { "name": "29 - 212 vip", "category": "Homme" },
      { "name": "30 - 212 men", "category": "Homme" }
  ],

  "Jean-Paul": [
    { name: "2 - Scandal", category: "Femme" },
    { name: "3 - La belle", category: "Femme" },
    { "name": "32 - Ultra mâle", "category": "Homme" },
      { "name": "31 - Scandal jean", "category": "Homme" }
  ],

  "Giorgio Armani": [
    { name: "4 - Si armani", category: "Femme" },
    { name: "5 - My way", category: "Femme" },
    { "name": "33 - Acqua di giò", "category": "Homme" },
      { "name": "34 - you", "category": "Homme" }
  ],

  "Arabian OUD": [
    { name: "6 - Kalimat", category: "Femme" },
  ],

  "Gucci": [
    { name: "7 - Gucci bloom", category: "Femme" },
  ],

  "Yves Saint Laurent": [
    { name: "8 - Libre Ysl", category: "Femme" },
    { name: "9 - Cinema", category: "Femme" },
    { name: "10 - Mon paris", category: "Femme" },
    { name: "11 - Black opium", category: "Femme" },
  ],

  "Elie Saab": [
    { name: "15 - Elie Saab", category: "Femme" },
  ],

  "Paco Rabanne": [
    { name: "16 - Olympea", category: "Femme" },
    { "name": "47 - Black xs l’excès", "category": "Homme" },
    { "name": "48 - One million elixir", "category": "Homme" },
    { "name": "49 - One million", "category": "Homme" },
    { "name": "50 - Invictus", "category": "Homme" },
    { "name": "51 - One million lucky", "category": "Homme" }
  ],

  "Escada": [
    { name: "17 - taj sunset", category: "Femme" },
  ],

  "Lancome": [
    { name: "18 - IdOLe", category: "Femme" },
    { name: "19 - La vie est belle", category: "Femme" },
    { name: "20 - La nuit tresor", category: "Femme" },
  ],

  "Victoria's": [
    { name: "21 - Concnut passion", category: "Femme" },
    
  ],

  "Burberry": [
    { name: "22 - My Burberry", category: "Femme" },
    
  ],

  "Oriflame": [
    { name: "23 - Amber Elixir", category: "Femme" },
    { name: "24 - Giordani gold", category: "Femme" },
    
  ],

  "Chanel": [
    { name: "25 - Coco mademoise", category: "Femme" },
    { name: "26 - Coco mademoise l'eau prive", category: "Femme" },
    { "name": "57 - Bleu de chanel", "category": "Homme" },
    
  ],

  
    
   
   
    "Lattafa": [
      { "name": "35 - Oud moud", "category": "Homme" },
      { "name": "36 - Cheikh chuyukh", "category": "Homme" }
    ],
    "Lacoste": [
      { "name": "37 - lacoste noir", "category": "Homme" },
      { "name": "38 - lacoste Blanc", "category": "Homme" }
    ],
    "Yves Saint": [
      { "name": "39 - La nuit de l’homme", "category": "Homme" }
    ],
    "Dolce & Gabbana": [
      { "name": "40 - The one men", "category": "Homme" }
    ],
    "Dior": [
      { "name": "41 - dior homme intense", "category": "Homme" },
      { "name": "42 - Sauvage elixir", "category": "Homme" },
      { "name": "43 - Fahrenheit", "category": "Homme" },
      { "name": "44 - Sauvage", "category": "Homme" }
    ],
    "Gugo Boss": [
      { "name": "45 - BOss the scent intense", "category": "Homme" },
      { "name": "46 - Hugo", "category": "Homme" }
    ],
   
    "Azzaro": [
      { "name": "52 - azzaro wanted", "category": "Homme" }
    ],
    "Kolmaz": [
      { "name": "53 - Le Môle luxe collection", "category": "Homme" }
    ],
    "Tom Ford": [
      { "name": "54 - black orchid", "category": "Homme" }
    ],
    "Creed": [
      { "name": "55 - Creed aventus", "category": "Homme" }
    ],
    "Givenchy": [
      { "name": "56 - Pi givenchy", "category": "Homme" }
    ],
    
    "Cartier": [
      { "name": "58 - déclaration", "category": "Homme" }
    ],
    "Maison Francis": [
      { "name": "59 - Baccarat rouge 540", "category": "Homme" }
    ],
    "Arabian Oud": [
      { "name": "60 - Kalimat", "category": "Homme" }
    ]
  }
  

  






  const PerfumeList = () => {
    const [currentBrandIndex, setCurrentBrandIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(''); // State for category filter
  
    // Filter brands based on search term and whether they have perfumes in the selected category
    const filteredBrands = Object.keys(perfumes).filter(brand => {
      const brandHasCategory = perfumes[brand].some(perfume => {
        return selectedCategory === '' || perfume.category === selectedCategory;
      });
      return brand.toLowerCase().includes(searchTerm.toLowerCase()) && brandHasCategory;
    });
  
    // Adjust current brand index if needed (e.g., when the filter reduces the brand list)
    useEffect(() => {
      if (currentBrandIndex >= filteredBrands.length) {
        setCurrentBrandIndex(filteredBrands.length - 1);
      }
    }, [filteredBrands, currentBrandIndex]);
  
    // Navigate to the next or previous brand
    const handleNext = () => {
      if (currentBrandIndex < filteredBrands.length - 1) {
        setCurrentBrandIndex(currentBrandIndex + 1);
      }
    };
  
    const handlePrevious = () => {
      if (currentBrandIndex > 0) {
        setCurrentBrandIndex(currentBrandIndex - 1);
      }
    };
  
    // Filter perfumes based on selected category and brand
    const filteredPerfumes = perfumes[filteredBrands[currentBrandIndex]]?.filter(perfume => {
      return selectedCategory === '' || perfume.category === selectedCategory;
    }) || [];
  
  const ads = [ads4, ads2, ads3, ads5 , ads6 , ads8 , ads9 , ads10 , ads11 , ads12 , ads13 ]; // Array of ads
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const adInterval = setInterval(() => {
      setCurrentAdIndex(prevIndex => (prevIndex + 1) % ads.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(adInterval);
  }, [ads.length]);

  return (
    
    <div className="ad-container">
  
  <div className="ad-space ad-left">
  <img src={ads1} alt="ads1" className="ad-img" />
</div>


<div>
      <LanguageToggleVip
     
   />

    </div>
    

  <div className="content-container">
      <div className="card-slider">
        <div className="bar123">
          <input
            type="text"
            placeholder="Rechercher des marques"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Filter Buttons for Category */}
        <div className="filterbuttons12">
          <button className="filterbuttonsT" onClick={() => setSelectedCategory('')}>Tous</button>
          <button className="filterbuttonsY" onClick={() => setSelectedCategory('Homme')}><RiMenLine /></button>
          <button className="filterbuttonsX" onClick={() => setSelectedCategory('Femme')}><RiWomenLine /></button>
        </div>

      
     

        {/* Perfume List */}
        <div className="perfume-card">
  <div className="brand-title">{filteredBrands[currentBrandIndex]}</div>
  <ul className="perfume-list vertical-list">
    {filteredPerfumes.map((perfume, index) => (
      <li
        key={index}
        className={`perfume-item ${
          perfume.category.toLowerCase() === "homme" ? "homme" : perfume.category.toLowerCase() === "femme" ? "femme" : ""
        }`}
      >
        <div className="perfume-name">{perfume.name}</div>
        <div className="perfume-category">{perfume.category}</div>
      </li>
    ))}
  </ul>
</div>


      


      <div className="navigation-buttons">
        <button onClick={handlePrevious} disabled={currentBrandIndex === 0}>
          <GrCaretPrevious />
        </button>
        <button
          onClick={handleNext}
          disabled={currentBrandIndex === filteredBrands.length - 1}
        >
          <GrCaretNext />
        </button>
      </div>
    </div>
  </div>




  <div className="ad-space ad-right">
      <img src={ads[currentAdIndex]} alt="ad" />
    </div>
</div>

  );
};

const LandingPage = () => {


  return (
    <div className="landing-page">
      <header>
      
      </header>
      <div className='scrollingaard'>
      <div className="scrolling-text">
          <p>تخفيضات حصرية والتوصيل مجاني و سريع</p>
        </div>
        </div>
      <main>

      <section className="hero">
      
  <div className="hero-content">
  </div>
</section>

    <section>
    <div className="buttonbar">
    <a href="#order-form" className="order-button">Commander <span style={{fontSize:"15px" , alignItems:"center" }}><IoIosCart /></span></a>
    </div>
    </section>
      
        <div className="logo-carousel">
      <div className="carousel-track">
        {perfumeLogos.map((logo, index) => (
          <div key={index} className="carousel-item">
            <img src={logo.logo} alt={`${logo.brand} Logo`} />
          </div>
        ))}
      </div>
    </div>
    <div style={{textAlign:"center" , fontFamily:"Tajawal" , fontSize:"16px"  , direction:"rtl" , display:"flex" , justifyContent:"center" , alignItems:"center"  , marginTop:"0%" , marginBottom:"0%"}}>

      <p><span style={{color:"#FFD700"}} className="pulsating-stars">
    <GoStarFill /> <GoStarFill /> <GoStarFill /> <GoStarFill /> <GoStarFill />
  </span><br></br>في قيام، نصنع عطوراً راقية بجودة عالية ومكونات فاخرة. نضمن لك تجربة تدوم طويلاً وتترك انطباعاً لا يُنسى.<br></br><span style={{fontWeight:"400" , color:"#FFD700"}} className="pulsating-stars">الفخامة تعني اختيار الأفضل دائماً</span></p>
    </div>
   

        <section className="perfume-list-section">
          {/* <h2 style={{fontFamily:"tajawal" , fontSize:"20px" , fontWeight:"800"}}>اشتر 3 واحصل على 1 بالمجان</h2> */}
          <PerfumeList />
        </section>

<ShopByType/>  

<section className="order-form-section" id="order-form">
          <OrderForm addOrder={(order) => console.log(order)} />
        </section>
        






        <div className="features-container">
  <div className="feature-item">
    <div className="feature-icon">
      <i className="fas fa-cog"></i>
    </div>
    <div className="feature-text">
    <img src={qualityIcon} alt="Best Quality" style={{ width: '40px', height: '40px' }}  />
      <h3>Premium Quality<br></br>Qualité Supérieure<span style={{fontFamily:"Tajawal"}} ><br></br>جودة عالية</span></h3>
      <p>Crafted with precision and care, offering long-lasting luxury.</p>
      <p>Fabriqué avec précision et soin, offrant un luxe durable.</p>
      <p style={{fontFamily:"Tajawal"}} >مصممة بدقة وعناية، لتوفير رفاهية تدوم طويلاً.</p>
     
    </div>
  </div>

  <div className="feature-item">
    <div className="feature-icon">
      <i className="fas fa-shipping-fast"></i>
    </div>
    <div className="feature-text">
    <img src={shipping} alt="Free Shipping" style={{ width: '40px', height: '40px' }} />
      <h3>Free Shipping<br></br>Livraison Gratuite<span style={{fontFamily:"Tajawal"}} ><br></br>شحن مجاني</span></h3>
      <p>Benefit from free shipping on all orders with secure, fast delivery.</p>
      <p>Profitez de la livraison gratuite sur toutes vos commandes avec une livraison rapide et sécurisée.</p>
      <p style={{fontFamily:"Tajawal"}}>استفد من الشحن المجاني على جميع الطلبات مع توصيل آمن وسريع.</p>
    
    </div>
  </div>

  <div className="feature-item">
    <div className="feature-icon">
      <i className="fas fa-check-circle"></i>
    </div>
    <div className="feature-text">
    <img src={warranty} alt="Warranty" style={{ width: '40px', height: '40px' }}  />
    <h3>Satisfaction Guarantee<br></br>Garantie de Satisfaction<span style={{fontFamily:"Tajawal"}} ><br></br>ضمان الرضا</span></h3>
      <p>Our extended warranty ensures your complete satisfaction.</p>
      <p>Notre garantie prolongée vous assure une satisfaction totale.</p>
      <p style={{fontFamily:"Tajawal"}} > يضمن ضماننا الممتد رضاك الكامل.</p>
     
    </div>
  </div>
</div>
<PerfumeTips />




        
      </main>

      <footer className="footer">
  <div className="footer-container">
    <div className="footer-content">
      <p>&copy; 2025 <span style={{fontWeight:"600"}}>Qeyam-Perfume</span>. All rights reserved.<br></br><span style={{fontSize:"10px" , color:"#6a4d1e"}}>by aghenda @a_naaas</span></p>
      <div className="social-icons">
        <a href="https://www.tiktok.com/@byqeyam" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-f"><AiFillTikTok /></i>
        </a>
        <a href="https://www.instagram.com/byqeyam/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"><PiInstagramLogoFill /></i>
        </a>
        <a href="https://wa.me/+212628952639" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin-in"><FaWhatsapp /></i>
        </a>
      </div>
    </div>
  </div>
</footer>


      <a href="https://wa.me/+212628952639" target="_blank" rel="noopener noreferrer">
  <div className="floating-whatsapp-button">
    <FaWhatsapp size={24} />
  </div>
</a>

    </div>
  );
};

export default LandingPage;