import React, { useEffect, useState } from 'react';
import './ShopByType.css'; // Import the CSS file for styling
import { FaGifts } from "react-icons/fa6";
import { GiPerfumeBottle } from "react-icons/gi";
import { MdAirplanemodeActive } from "react-icons/md";
import { GiHypodermicTest } from "react-icons/gi";

const ShopByType = () => {
  const [language, setLanguage] = useState('en'); // Default language is English

  // Categories in different languages
  const categories = {
    en: [
      {
        icon: <GiHypodermicTest style={{color:"#7C9A6D"}} />,
        title: 'Testers',
        description: 'Full-size products without the fancy packaging',
      },
      {
        icon: <MdAirplanemodeActive style={{color:"#6A8FA6"}}  />,
        title: "Travel Mini's",
        description: 'Travel-sized scents, perfect for on-the-go',
      },
      {
        icon: <FaGifts style={{color:"#E08D3C"}}  />,
        title: 'Gift Sets',
        description: 'Beautifully packaged sets that make for a perfect gift',
      },
      {
        icon: <GiPerfumeBottle style={{color:"#B2544F"}} />,
        title: 'Samples',
        description: 'Discover your perfect match with samples',
      },
    ],
    fr: [
      {
        icon: <GiHypodermicTest style={{color:"#7C9A6D"}} />,
        title: 'Testeurs',
        description: 'Produits en taille réelle sans l’emballage sophistiqué',
      },
      {
        icon: <MdAirplanemodeActive style={{color:"#6A8FA6"}} />,
        title: 'Mini Voyage',
        description: 'Parfums de voyage, parfaits pour emporter',
      },
      {
        icon: <FaGifts style={{color:"#E08D3C"}} />,
        title: 'Coffrets cadeaux',
        description: 'Coffrets magnifiquement emballés, parfaits pour un cadeau',
      },
      {
        icon: <GiPerfumeBottle style={{color:"#B2544F"}} />,
        title: 'Échantillons',
        description: 'Découvrez votre parfum parfait avec des échantillons',
      },
    ],
    ar: [
      {
        icon: <GiHypodermicTest style={{color:"#7C9A6D"}} />,
        title: 'عينات تجريبية',
        description: 'منتجات كاملة الحجم بدون العبوة الفاخرة',
      },
      {
        icon: <MdAirplanemodeActive style={{color:"#6A8FA6"}} />,
        title: 'أحجام السفر',
        description: 'عطور بحجم السفر، مثالية أثناء التنقل',
      },
      {
        icon: <FaGifts style={{color:"#E08D3C"}} />,
        title: 'مجموعات الهدايا',
        description: 'مجموعات مغلفة بشكل جميل، هدية مثالية',
      },
      {
        icon: <GiPerfumeBottle style={{color:"#B2544F"}} />,
        title: 'عينات',
        description: 'اكتشف عطرك المثالي مع العينات',
      },
    ],
  };

  // Title translations
  const titles = {
    en: 'Shop By Type',
    fr: 'Acheter par Type',
    ar: 'التسوق حسب الطلب',
  };

  // Automatically cycle through languages every 5 seconds
  useEffect(() => {
    const languageCycle = setInterval(() => {
      setLanguage((prevLanguage) => {
        if (prevLanguage === 'en') return 'fr';
        if (prevLanguage === 'fr') return 'ar';
        return 'en'; // Loop back to English
      });
    }, 5000); // Change language every 5 seconds

    return () => clearInterval(languageCycle); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className={`shop-by-type ${language === 'ar' ? 'arabic' : ''}`}>
      <h2 className="shop-title">{titles[language]}</h2>

      <div className="shop-grid">
        {categories[language].map((category, index) => (
          <div key={index} className="shop-item">
            <div className="shop-icon">{category.icon}</div>
            <h3 className="shop-title-item">{category.title}</h3>
            <p className="shop-description">{category.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopByType;
