// Import the Firebase dependencies
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzaWoKXNvICb0thOWp2VCLEHazd0MbqX8",
  authDomain: "qeyam-store.firebaseapp.com",
  projectId: "qeyam-store",
  storageBucket: "qeyam-store.appspot.com",
  messagingSenderId: "18187495826",
  appId: "1:18187495826:web:9aff609643b3a9acd2f94b",
  measurementId: "G-MDCF3Z0K9B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

export { db };



